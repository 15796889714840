import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';
import { Layout, Menu, Button } from 'antd';
import {
  ApiOutlined,
  FileTextOutlined,
  IdcardOutlined,
  MessageOutlined,
  SoundOutlined,
  ToolOutlined,
  WalletOutlined,
  CommentOutlined,
  ReconciliationOutlined,
} from '@ant-design/icons';
// import { ChattingPage } from 'pages';
import { getOtherAdminList, checkAuth } from 'controllers/login';
import { routes_ref } from 'lib/routeRef';
import Dropdown from 'components/Common/DropDown/DropDown';
import DropDownButton from 'components/Common/DropDown/DropDownButton';
import styles from './MenuPane.scss';
const cx = classNames.bind(styles);

// const { SubMenu, Item } = Menu;
const { Sider } = Layout;
const MenuPane = (props) => {
  const { selectedOpenKey, selectedMenuKey } = props;
  const [visible, setVisible] = useState(true);
  // const [chatPage, setChatPage] = useState(false);
  const [menuData, setMenuData] = useState({
    permissions: {},
    otherAdminLogin: {
      db: {
        id: '',
        pwd: '',
      },
      ss: {
        id: '',
        pwd: '',
      },
    },
  });

  const { permissions, otherAdminLogin } = menuData;

  useEffect(() => {
    const getDataList = {
      permissions: {},
      otherAdminLogin: {},
    };
    const fetchDataList = async () => {
      getDataList.permissions = (await checkAuth()) || {};
      getDataList.otherAdminLogin = (await getOtherAdminList()) || {};
      await setMenuData(getDataList);
    };
    fetchDataList();
  }, []);

  // const dbUrl =
  //   'https://dbins.aaihc.com/shbs/login_check.php?url=https%3A%2F%2Fdbins.aaihc.com%2Fmanager';
  // const ssUrl =
  //   'https://sslife.aaihc.com/shbs/login_check.php?url=https%3A%2F%2Fsslife.aaihc.com%2Fmanager';
  const {
    admins: { admin },
    members: { member, login_log, hana_step },
    services: { apply, p_apply, mediCert, mental },
    contents: { notice, faq, treatment, event_info, push },
    outers: {
      // mobile_consulting,
      art_consulting,
      helper_group,
      helper,
      healthy_body,
      bbiddaki_api_log,
      benefits,
    },
    healthLogs: {
      cancer_log,
      medicine,
      growth_rec,
      body_age,
      dzPreds,
      steps,
      dentals,
    },
    systems: {
      app_version,
      app_hash,
      admin_access_log,
      admin_login_log,
      admin_mod_log,
    },
    chatbot: { chatting, chat_apply, health_type, center, doctor, chat_qna, chat_term },
  } = routes_ref.base;

  const items = [
    {
      key: 'aaihc',
      label: '헬스케어 서비스 관리',
      children: [
        {
          style: { paddingLeft: '33px' },
          key: apply.path,
          label: (
            <Link to={apply.path}>
              <MessageOutlined />
              &nbsp;&nbsp;{apply.label.label}
            </Link>
          ),
        },
        {
          style: { paddingLeft: '33px' },
          key: mediCert.path,
          label: (
            <Link to={mediCert.path}>
              <MessageOutlined />
              &nbsp;&nbsp;{mediCert.label.label}
            </Link>
          ),
        },
        // {
        //   style: { paddingLeft: '33px' },
        //   key: admin.path,
        //   label: (
        //     <Link to={admin.path}>
        //       <WalletOutlined />
        //       &nbsp;&nbsp;{admin.label.label}
        //     </Link>
        //   ),
        // },
        {
          style: { paddingLeft: '33px' },
          key: member.path,
          label: (
            <Link to={member.path}>
              <IdcardOutlined />
              &nbsp;&nbsp;{member.label.label}
            </Link>
          ),
        },
        {
          style: { paddingLeft: '33px' },
          key: login_log.path,
          label: (
            <Link to={login_log.path}>
              <ApiOutlined />
              &nbsp;&nbsp;{login_log.label.label}
            </Link>
          ),
        },
      ],
    },
    {
      key: 'healthLogs',
      label: '건강 관리',
      children: [
        {
          style: { paddingLeft: '33px' },
          key: cancer_log.path,
          label: (
            <Link to={cancer_log.path}>
              <FileTextOutlined />
              &nbsp;&nbsp;{cancer_log.label.label}
            </Link>
          ),
        },
        {
          style: { paddingLeft: '33px' },
          key: body_age.path,
          label: (
            <Link to={body_age.path}>
              <FileTextOutlined />
              &nbsp;&nbsp;{body_age.label.label}
            </Link>
          ),
        },
        {
          style: { paddingLeft: '33px' },
          key: dzPreds.path,
          label: (
            <Link to={dzPreds.path}>
              <FileTextOutlined />
              &nbsp;&nbsp;{dzPreds.label.label}
            </Link>
          ),
        },
        {
          style: { paddingLeft: '33px' },
          key: steps.path,
          label: (
            <Link to={steps.path}>
              <FileTextOutlined />
              &nbsp;&nbsp;{steps.label.label}
            </Link>
          ),
        },
        {
          style: { paddingLeft: '33px' },
          key: dentals.path,
          label: (
            <Link to={dentals.path}>
              <FileTextOutlined />
              &nbsp;&nbsp;{dentals.label.label}
            </Link>
          ),
        },
      ],
    },
    {
      key: 'outers',
      label: '외부 관리자',
      children: [
        {
          style: { paddingLeft: '33px' },
          key: art_consulting.path,
          label: (
            <Link to={art_consulting.path}>
              <MessageOutlined />
              &nbsp;&nbsp;{art_consulting.label.label}
            </Link>
          ),
        },
        {
          style: { paddingLeft: '33px' },
          key: helper_group.path,
          label: (
            <Link to={helper_group.path}>
              <MessageOutlined />
              &nbsp;&nbsp;{helper_group.label.label}
            </Link>
          ),
        },
        {
          style: { paddingLeft: '33px' },
          key: helper.path,
          label: (
            <Link to={helper.path}>
              <MessageOutlined />
              &nbsp;&nbsp;{helper.label.label}
            </Link>
          ),
        },
        {
          style: { paddingLeft: '33px' },
          key: healthy_body.path,
          label: (
            <Link to={healthy_body.path}>
              <MessageOutlined />
              &nbsp;&nbsp;{healthy_body.label.label}
            </Link>
          ),
        },
        {
          style: { paddingLeft: '33px' },
          key: bbiddaki_api_log.path,
          label: (
            <Link to={bbiddaki_api_log.path}>
              <MessageOutlined />
              &nbsp;&nbsp;{bbiddaki_api_log.label.label}
            </Link>
          ),
        },
        {
          style: { paddingLeft: '33px' },
          key: benefits.path,
          label: (
            <Link to={benefits.path}>
              <MessageOutlined />
              &nbsp;&nbsp;{benefits.label.label}
            </Link>
          ),
        },
      ],
    },
    {
      key: 'chatbot',
      label: 'AI건강상담 챗봇',
      children: [
        {
          key: 'chatbot-service',
          label: '서비스 관리',
          children: [
            {
              key: chatting.path,
              label: (
                <>
                  <Link to={chatting.path}>
                    <CommentOutlined />
                    &nbsp;&nbsp;{chatting.label.label}
                  </Link>
                </>
              ),
            },
            {
              key: chat_apply.path,
              label: (
                <Link to={chat_apply.path}>
                  <MessageOutlined />
                  &nbsp;&nbsp;{chat_apply.label.label}
                </Link>
              ),
            },
            {
              key: health_type.path,
              label: (
                <Link to={health_type.path}>
                  <FileTextOutlined />
                  &nbsp;&nbsp;{health_type.label.label}
                </Link>
              ),
            },
          ],
        },
        {
          key: 'chatbot-contents',
          label: '컨텐츠 관리',
          children: [
            {
              key: center.path,
              label: (
                <Link to={center.path}>
                  <ReconciliationOutlined />
                  &nbsp;&nbsp;{center.label.label}
                </Link>
              ),
            },
            {
              key: doctor.path,
              label: (
                <Link to={doctor.path}>
                  <ReconciliationOutlined />
                  &nbsp;&nbsp;{doctor.label.label}
                </Link>
              ),
            },
            //
            {
              key: chat_qna.path,
              label: (
                <Link to={chat_qna.path}>
                  <ReconciliationOutlined />
                  &nbsp;&nbsp;{chat_qna.label.label}
                </Link>
              ),
            },{
              key: chat_term.path,
              label: (
                <Link to={chat_term.path}>
                  <ReconciliationOutlined />
                  &nbsp;&nbsp;{chat_term.label.label}
                </Link>
              ),
            },
          ],
        },
        {
          key: 'chatbot-admin',
          label: '설정 관리',
          children: [
            {
              key: center.path,
              label: (
                <Link>
                  <ReconciliationOutlined />
                  &nbsp;&nbsp;{'고객사'}
                </Link>
              ),
            },
            {
              key: doctor.path,
              label: (
                <Link>
                  <ReconciliationOutlined />
                  &nbsp;&nbsp;{'상품 그룹'}
                </Link>
              ),
            },
            //
            {
              key: doctor.path,
              label: (
                <Link>
                  <ReconciliationOutlined />
                  &nbsp;&nbsp;{'상품'}
                </Link>
              ),
            }
          ],
        },
        // {
        //   key: chatting.path,
        //   label: (
        //     <>
        //       <Link to={chatting.path}>
        //     <CommentOutlined />
        //     &nbsp;&nbsp;{chatting.label.label}
        //   </Link>
        //     </>
        //   ),
        // },
        // {
        //   key: chat_apply.path,
        //   label: (
        //     <Link to={chat_apply.path}>
        //       <MessageOutlined />
        //       &nbsp;&nbsp;{chat_apply.label.label}
        //     </Link>
        //   ),
        // },
        // {
        //   key: health_type.path,
        //   label: (
        //     <Link to={health_type.path}>
        //       <FileTextOutlined />
        //       &nbsp;&nbsp;{health_type.label.label}
        //     </Link>
        //   ),
        // },
        // {
        //   key: center.path,
        //   label: (
        //     <Link to={center.path}>
        //       <ReconciliationOutlined />
        //       &nbsp;&nbsp;{center.label.label}
        //     </Link>
        //   ),
        // },
        // {
        //   key: doctor.path,
        //   label: (
        //     <Link to={doctor.path}>
        //       <ReconciliationOutlined />
        //       &nbsp;&nbsp;{doctor.label.label}
        //     </Link>
        //   ),
        // },
      ],
    },
    {
      key: 'contents',
      label: '컨텐츠 관리',
      children: [
        {
          style: { paddingLeft: '33px' },
          key: notice.path,
          label: (
            <Link to={notice.path}>
              <SoundOutlined />
              &nbsp;&nbsp;{notice.label.label}
            </Link>
          ),
        },
        {
          style: { paddingLeft: '33px' },
          key: treatment.path,
          label: (
            <Link to={treatment.path}>
              <SoundOutlined />
              &nbsp;&nbsp;{treatment.label.label}
            </Link>
          ),
        },
        {
          style: { paddingLeft: '33px' },
          key: event_info.path,
          label: (
            <Link to={event_info.path}>
              <SoundOutlined />
              &nbsp;&nbsp;{event_info.label.label}
            </Link>
          ),
        },
        {
          style: { paddingLeft: '33px' },
          key: push.path,
          label: (
            <Link to={push.path}>
              <SoundOutlined />
              &nbsp;&nbsp;{push.label.label}
            </Link>
          ),
        },
      ],
    },
    {
      key: 'systems',
      label: '시스템 관리',
      children: [
        {
          style: { paddingLeft: '33px' },
          key: admin.path,
          label: (
            <Link to={admin.path}>
              <WalletOutlined />
              &nbsp;&nbsp;{admin.label.label}
            </Link>
          ),
        },
        {
          style: { paddingLeft: '33px' },
          key: app_version.path,
          label: (
            <Link to={app_version.path}>
              <ToolOutlined />
              &nbsp;&nbsp;{app_version.label.label}
            </Link>
          ),
        },
        {
          style: { paddingLeft: '33px' },
          key: app_hash.path,
          label: (
            <Link to={app_hash.path}>
              <ToolOutlined />
              &nbsp;&nbsp;{app_hash.label.label}
            </Link>
          ),
        },
        {
          style: { paddingLeft: '33px' },
          key: admin_access_log.path,
          label: (
            <Link to={admin_access_log.path}>
              <ToolOutlined />
              &nbsp;&nbsp;{admin_access_log.label.label}
            </Link>
          ),
        },
        {
          style: { paddingLeft: '33px' },
          key: admin_login_log.path,
          label: (
            <Link to={admin_login_log.path}>
              <ToolOutlined />
              &nbsp;&nbsp;{admin_login_log.label.label}
            </Link>
          ),
        },
        {
          style: { paddingLeft: '33px' },
          key: admin_mod_log.path,
          label: (
            <Link to={admin_mod_log.path}>
              <ToolOutlined />
              &nbsp;&nbsp;{admin_mod_log.label.label}
            </Link>
          ),
        },
      ],
    },
  ];

  return (
    <>
      <DropDownButton
        visible={visible}
        setVisible={setVisible}
      ></DropDownButton>
      <Dropdown visible={visible}>
        <Sider width={240} className="site-layout-background">
          <Menu
            mode="inline"
            defaultOpenKeys={selectedOpenKey}
            defaultSelectedKeys={[selectedMenuKey]}
            style={{ height: '100%', borderRight: 0 }}
            items={items}
          ></Menu>
        </Sider>
      </Dropdown>
    </>
  );
};

export default MenuPane;
